import React from 'react';
import { graphql, HeadFC, PageProps } from 'gatsby';
import Seo from '../../../components/Seo';
import { getArticles, getArticle, getDefaultSEO } from '../../../utils';
import Business from '../../../components/Business';
import dayjs from 'dayjs';
import { businessCreditCardsRelatedContent } from '../../../data/relatedContent';
import HTPageWrapper from '../../../wrappers/HTPageWrapper';

interface IBusinessPageProps {
  businessCreditCardGuides: Queries.WpPostConnection;
  latestBusinessCreditCardGuides: Queries.WpPostConnection;
  featuredGuide: Queries.WpPost;
}

const BusinessPage = ({ data, location }: PageProps<IBusinessPageProps>) => {
  const { featuredGuide, businessCreditCardGuides, latestBusinessCreditCardGuides } = data;
  let latestPosts: Queries.WpPost[] = [];
  let businessCreditCardPosts: Queries.WpPost[] = [];

  if (latestBusinessCreditCardGuides && Array.isArray(latestBusinessCreditCardGuides.nodes)) {
    latestPosts = latestBusinessCreditCardGuides.nodes;
  }

  if (businessCreditCardGuides && Array.isArray(businessCreditCardGuides.nodes)) {
    businessCreditCardPosts = businessCreditCardGuides.nodes;
  }

  const latestArticles = getArticles(latestPosts);
  const businessCreditCardArticles = getArticles(businessCreditCardPosts).map((article) => ({
    ...article,
    category: {
      link: false,
      text: 'Business',
    },
    imageOverlay: true,
  }));
  // Card IDs to be confirmed
  const cardIds = [285059, 231162, 221656, 205767, 183935, 156359];
  const businessCreditCards = cardIds.map((cardId) => ({ cardId }));
  const featured = getArticle(featuredGuide);
  const title = 'Business';

  return (
    <HTPageWrapper category="Hub Page, Business" title={title} location={location}>
      <Business
        pageHeader={{
          title: title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
          ],
        }}
        categoryNav={{
          variant: 'category',
          subtitle: 'Explore the best business credit cards',
          categories: [
            {
              link: '/credit-cards/business/best-chase-business-credit-cards/',
              text: 'Chase Business Credit Cards',
              class: 'credit-card-button',
            },
            {
              link: '/credit-cards/business/best-amex-business-credit-cards/',
              text: 'Amex Business Credit Cards',
              class: 'credit-card-button',
            },
            {
              link: '/credit-cards/business/best-capital-one-business-credit-cards/',
              text: 'Capital One Business Credit Cards',
              class: 'credit-card-button',
            },
            {
              link: '/credit-cards/business/best-wells-fargo-business-credit-cards/',
              text: 'Wells Fargo Business Credit Cards',
              class: 'credit-card-button',
            },
            {
              link: '/credit-cards/business/best-business-credit-cards/',
              text: 'Best Business Credit Cards',
              class: 'cat-business',
            },
            {
              link: '/credit-cards/business/best-business-credit-cards-for-advertising-purchases/',
              text: 'Best Cards for Advertisement',
              class: 'cat-advertisement',
            },
            {
              link: '/credit-cards/business/best-business-credit-cards-for-shipping-purchases/',
              text: 'Best Cards for Shipping',
              class: 'cat-shipping',
            },
          ],
        }}
        businessCreditCardReviews={{
          // To be confirmed See All
          seeAllLink: {
            link: '/credit-cards/reviews/',
            text: 'See All Reviews',
          },
          cards: businessCreditCards,
          title: 'Business Credit Card Reviews',
          subtitle:
            'Our experts do a thorough analysis of all of the most popular credit cards on the market',
        }}
        businessCreditCardGuides={{
          articles: businessCreditCardArticles,
          title: 'Business Credit Card Guides',
          moreLink: {
            link: '/credit-cards/guides/',
            text: 'See All',
          },
        }}
        featuredGuide={{
          featuredImage: featured.thumbnail,
          moreLink: {
            link: featured.link,
            text: 'Learn More',
          },
          title: featured.title,
          hasTransparentBackground: true,
        }}
        latestBusinessGuides={{
          moreLink: {
            link: '/credit-cards/guides/',
            text: 'See More',
          },
          variant: 'columns',
          articles: latestArticles,
          title: 'Latest Business Guides',
        }}
        topRelatedContent={{
          categories: businessCreditCardsRelatedContent,
          title: 'Top Credit Card Content',
        }}
      />
    </HTPageWrapper>
  );
};

export default BusinessPage;

export const query = graphql`
  query BusinessQuery {
    latestBusinessCreditCardGuides: allWpPost(
      sort: { date: DESC }
      limit: 6
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Business" } } } }
        template: { templateName: { ne: "Credit Card Review" } }
      }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        author {
          node {
            uri
            name
          }
        }
        title
        uri
        dateGmt
        excerpt
      }
    }
    featuredGuide: wpPost(databaseId: { eq: 285248 }) {
      uri
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
    businessCreditCardGuides: allWpPost(
      filter: { databaseId: { in: [16298, 131405, 131589, 144688, 133704, 135080] } }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            id
            parentId
          }
        }
        title
        uri
      }
    }
  }
`;

export const Head: HeadFC = ({ location }) => {
  const date = dayjs();
  const socialTitle = `Best Business Credit Cards of ${date.format('MMM YYYY')}`;
  const pageTitle = socialTitle + ' - Upgraded Points';
  const extraBreadcrumbList = [
    { name: 'Credit Cards', slug: 'credit-cards' },
    { name: 'Business', slug: 'business' },
  ];
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    socialTitle,
    false,
    extraBreadcrumbList,
  );
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      isArchivePage={true}
      archiveName={'Business'}
      uri={'/credit-cards/business/'}
    />
  );
};
