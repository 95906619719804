import React, { FC } from 'react';
import { IBusiness } from './Business.def';
import CategoryNav from '../CategoryNav';
import FeaturedGuide from '../FeaturedGuide';
import GuideBlocks from '../GuideBlocks';
import HorizontalBrowser from '../HorizontalBrowser';
import PageHeader from '../PageHeader';
import SquareBrowser from '../SquareBrowser';
import { TopRelatedContent } from '../TopRelatedContent';

const Business: FC<IBusiness> = ({
  pageHeader,
  categoryNav,
  featuredGuide,
  businessCreditCardReviews,
  latestBusinessGuides,
  businessCreditCardGuides,
  topRelatedContent,
}) => {
  return (
    <div className="businessPage">
      <PageHeader {...pageHeader} />
      <CategoryNav {...categoryNav} />
      <HorizontalBrowser {...businessCreditCardReviews} />
      <SquareBrowser {...businessCreditCardGuides} />
      <FeaturedGuide {...featuredGuide} />
      <GuideBlocks {...latestBusinessGuides} />
      {topRelatedContent?.title && <TopRelatedContent {...topRelatedContent} />}
    </div>
  );
};

export default Business;
